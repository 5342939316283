// axios
import axios from 'axios'
//axios.defaults.baseURL = 'http://51.38.57.172:8011/api/'cliniquetest


//axios.defaults.baseURL = 'http://51.38.57.172:8008/api/'clinique1
//axios.defaults.baseURL = 'http://51.38.57.172:8013/api/'clinique2
//axios.defaults.baseURL = 'http://51.38.57.172:8014/api/'clinique3
//axios.defaults.baseURL = 'http://51.38.57.172:8015/api/'clinique4
//axios.defaults.baseURL = 'http://51.38.57.172:8016/api/'clinique5
//axios.defaults.baseURL = 'http://51.38.57.172:8017/api/'clinique6


//axios.defaults.baseURL = 'https://moozimed.moozistudio.com/api/'
//axios.defaults.baseURL = 'https://clinique2.moozistudio.com/api/'
axios.defaults.baseURL = 'https://clinique3.moozistudio.com/api/'
//axios.defaults.baseURL = 'https://clinique4.moozistudio.com/api/'
//axios.defaults.baseURL = 'https://clinique5.moozistudio.com/api/'
//axios.defaults.baseURL = 'https://clinique6.moozistudio.com/api/'


axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`
export default axios.create({})
